import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Nossos Programas</h2>
          <h5 className={classes.description}>
            Os Programas 2ouMaisFisioSports foram criados para atender às suas
            necessidades específicas. Ao escolher um deles, você terá um
            treinamento adequado ao seu perfil e a garantia de maior satisfação
            com a atividade física. Contando também com reabilitações e
            prevenções de lesões, para a máxima performance, saúde e qualidade
            de vida.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Muay Thai"
              description="Treinos com técnicas da arte marcial, focados principalmente no emagrecimento e condicionamento físico."
              icon={Chat}
              imagem={require("assets/img/programs/boxing.svg")}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Funcional & Musculação"
              description="Alguns exercícios em alta intensidade focados totalmente em redução de medidas, ganho de massa muscular e modelagem corporal."
              icon={VerifiedUser}
              imagem={require("assets/img/programs/dumbell.svg")}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Alongamento"
              description="Além de melhorar a flexibilidade, o foco aqui é reduzir limitações e o relaxamento muscular, para permitir a execução correta do movimento.
              * Será avaliado a necessidade de aulas expecíficas de alongamento, vai depender da necessidade e avaliação."
              icon={Fingerprint}
              imagem={require("assets/img/programs/yoga.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Fisioterapia"
              description="Com foco na reabilitação, recuperação ou até mesmo na prevenção de lesões, trazendo mais conforto e alívio aos incomodos diários.
              * Necessário uma pré avaliação para determinar o tratamento."
              icon={Fingerprint}
              imagem={require("assets/img/programs/massage.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Acupuntura"
              description="Através da medicina chinesa combinada com tecnicas da ciencia moderna, desenvolvemos técnicas para reabilitção, recuperação ou até mesmo na prevenção de lesões, trazendo mais conforto e alívio aos incomodos diários.
              * Necessário uma pré avaliação para determinar o tratamento."
              icon={Fingerprint}
              imagem={require("assets/img/programs/massage.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Corrida"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Fingerprint}
              imagem={require("assets/img/programs/running.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Natação"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Fingerprint}
              imagem={require("assets/img/programs/swimming.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
